import { createContext, ReactNode, useEffect, useReducer } from "react";
import axios from "../utils/axios";
import { setSession } from "../utils/jwt";
import { ActionMap, AuthState, JWTContextType } from "../@types/auth";
import { AUTHENTICATE, LOG_IN } from "src/utils/constants/routeConstants";
import { User } from "src/@types/marketplaceUser";
import { updateMarkeplace } from "src/redux/slices/marketplace";

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [Types.Login]: {
    user: User | null;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: User;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (window.location.search.includes("marketplaceId")) {
          const params: string[] = window.location.search
            .split("?")[1]
            .split("&");
          const authenticateData: { token: string; marketplaceId: string } = {
            token: "",
            marketplaceId: "",
          };
          params.forEach((param: string) => {
            const splitParam = param.split("=");
            const key: string = splitParam[0];
            const value: string = splitParam[1];
            authenticateData[key as "token" | "marketplaceId"] = value;
          });

          const response = (await axios.post(
            `${AUTHENTICATE}`,
            authenticateData
          )) as {
            data: User;
          };
          updateMarkeplace(response?.data?.marketplace);
          setSession(response?.data?.accessToken);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: { ...response?.data, isIframe: true },
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = (await axios.post(`${LOG_IN}`, {
      email,
      password,
    })) as { data: User };
    updateMarkeplace(response?.data?.marketplace);
    setSession(response?.data?.accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user: { ...response?.data, isIframe: false },
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
